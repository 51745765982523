document.addEventListener("turbolinks:load", () => {
  // TagsInput Suggestags
  $('[data-role="tagsinput"]').amsifySuggestags({
    suggestionsAction: {
      url: '/tags_list'
    }//,
    // showAllSuggestions: true,
    // keepLastOnHoverTag: false
    // suggestions: [
    //       {tag: 'Black', value: 1},
    //       {tag: 'White', value: 2},
    //       {tag: 'Red', value: 3},
    //       {tag: 'Blue', value: 4},
    //       {tag: 'Green', value: 5},
    //       {tag: 'Orange', value: 6}
    //     ]
  })
  .on('suggestags.add', function(e){
    // Do something after adding tag
    console.log("Add tag");
  })
  .on('suggestags.change', function(e){
    // Do something while add/remove tag
    console.log("Add tag or remove tag");
  })
  .on('suggestags.remove', function(e){
    // Do something before removing tag
    console.log("Remove tag");
  });



  // Tags Tree
  $('#js_tree_tags')
  .bind("loaded.jstree", function (e, data) {
      data.instance.open_all();
  })
  .bind("refresh.jstree", function (e, data) {
      data.instance.open_all();
  })
  .jstree({
      "plugins" : [ "themes", "dnd", "state", "types", "wholerow", "sort", "search"], //, "stackedicon" ],  //
      "themes" : {
          "theme" : "default",
          "icons" : false
      },
      "core": {
          "animation" : 0 ,
          "check_callback" :  function (operation, node_obj, node_parent, node_position, more) {
                                // Pour empêcher le déplacement de type reorder
                                if (more && more.dnd && more.pos !== "i") { return false; }
                                // D&D
                                if (operation === "move_node") {
                                  return true
                                }
                                return true;
                              },
          "themes" : { "stripes" : true },
          "multiple" : false,
          "data" : { "url" : '/tags_js_tree_data',
                     "data" : function(node) {
                              return { 'id' : node.id };
                          }
                   }
      },
      //"dnd" : { "copy": false, "use_html5" : true, "insid e_pos" : "last" },
      "types" : {
                  "#" : { "icon": "fas fa-universal-access icon_tree_tag" },
                  'tag': { "icon": "fas fa-tag icon_tree_tag" }
                },
      // Tri les élems par ID => même ordre que dans la BDD
      "sort" : function (a, b) { return (parseInt(a) < parseInt(b)) ? -1 : 1; }
  })
  // Drag & Drop : déplacement d'un tag dans l'arbre
  .bind("move_node.jstree", function(e, data) {
      console.log("Déplacement du node " + data.node.id + " vers " + data.parent);
      let url = $("#"+data.node.id+"_link").attr('data-fct-dnd');
      console.log(url);

      let datas = {};
      datas = {'tag': { 'parent_id': data.parent}}
      $.ajax({
        url: url,
        type: 'PATCH',
        data: datas,
        dataType: 'script',
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));},
        success: function(data) {
          console.log('Update done.');
        }
      });
      data.instance.open_node(data.parent);
  })

  $('#expand_tags_tree').on("click", function() {
    $("#js_tree_tags").jstree("open_all");
  });
  $('#collapse_tags_tree').on("click", function() {
    $("#js_tree_tags").jstree("close_all");
  });
});
