
import { html2canvas } from 'html2canvas';

document.addEventListener("turbolinks:load", () => {
  // ToolTips
  $('[data-toggle="tooltip"]').tooltip();

  // Popover
  $('[data-toggle="popover"]').popover();

  $('[data-toggle="popover-html"]').popover({
    html: true,
    trigger: 'hover',
    content: function() {
      var content = $(this).attr("data-popover-content");
      return 'blabla'; // $(content).children(".popover-body").html();
    },
    title: function() {
      var title = $(this).attr("data-popover-content");
      return $(title).children(".popover-heading").html();
    }
    // ,
    // template: '<div class="popover popover-warning ' + $(this).attr("data-popover-class") + '" role="tooltip"><div class="arrow"></div><h3 class="popover-header"></h3><div class="popover-body"></div></div>'
  });
  // $('[data-toggle="popover-html"]').next('.popover').addClass('popover-warning');
  // ' + $(this).attr("data-popover-class") + '

  // Tablesorter
  $('[data-toggle="tablesort"]').tablesorter({
    theme : 'blue'
  });

  // $('.datetimepicker').datetimepicker();
  // $('.date_time_picker').datetimepicker();

  // // TEST Html2canvas
  // setTimeout(function(){
  //   // V2
  //   html2canvas(document.querySelector("#iskhikawa_zone")).then(canvas => {
  //       document.body.appendChild(canvas)
  //   });
  // }, 1000)
})
