// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

window.$ = window.jQuery = require('jquery');

require("jquery")
require("jquery-ui-dist/jquery-ui")

// Pour autosize fonctionnel dans best_in_place/textarea
import autosize from 'autosize';
$.fn.extend({autosize: autosize});

// require("jquery-ui")
// require("jquery-ui/ui/widget");

// require("bootstrap-tagsinput/dist/bootstrap-tagsinput")

require("suggestags")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../application/images', true)
const imagePath = (name) => images(name, true)

import 'popper.js'
import "bootstrap"
import 'packs/best_in_place'
// import 'application/javascript/best_in_place.jquery-ui'
import "packs/bip.js.erb";

import "packs/custom";
import "@fortawesome/fontawesome-free/js/all";

require("trix")
require("@rails/actiontext")


import "tablesorter"
// import "tablesorter/dist/js/jquery.tablesorter.widgets.js"
import "tablesorter/dist/js/widgets/widget-columnSelector.min.js" // compilation
import "jstree"
import "malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.concat.min.js"
// import 'best_in_place'

import 'jquery-circle-progress'

// Date Time picker
// require("moment/locale/fr")
// require("tempusdominus-bootstrap-4")

import { fabric } from "fabric";
// fabric.Object.prototype.objectCaching = false;

// Import i18nJS
import I18n from 'i18n-js/index.js.erb'
console.log(I18n.t('hello'))


// Chartjs
import { Chart } from 'chart.js';

import "chartkick/chart.js"

// import { domtoimage } from 'dom-to-image';
// import 'dom-to-image';
// console.log( domtoimage );
// console.log('DomToImage!');
// var domtoimage = require('dom-to-image');

// import { html2canvas } from 'html2canvas';
// html2canvas(document.querySelector("#iskhikawa_zone")).then(canvas => {
//     document.body.appendChild(canvas)
// });

// Import scss
import '../stylesheets/application'

// APPLICATION
import 'index.js'
// import { draw_ishikawa } from '../application/javascript/ishikawa.js.erb';
