document.addEventListener("turbolinks:load", () => {
  // Tree Contents Tree
  $('#js_tree_tree_content')
  .bind("loaded.jstree", function (e, data) {
      data.instance.open_all();
  })
  .bind("refresh.jstree", function (e, data) {
      data.instance.open_all();
  })
  .jstree({
      "plugins" : [ "themes", "dnd", "state", "types", "wholerow", "sort", "search"], //, "stackedicon" ],  //
      "themes" : {
          "theme" : "default",
          "icons" : false
      },
      "core": {
          "animation" : 0 ,
          "check_callback" :  function (operation, node_obj, node_parent, node_position, more) {
                                // Pour empêcher le déplacement de type reorder
                                if (more && more.dnd && more.pos !== "i") { return false; }
                                // D&D
                                if (operation === "move_node") {
                                  return true
                                }
                                return true;
                              },
          "themes" : { "stripes" : true },
          "multiple" : false,
          "data" : { "url" : '/meth/tree_contents_js_tree_data',
                     "data" : function(node) {
                              return { 'id' : node.id };
                          }
                   }
      },
      //"dnd" : { "copy": false, "use_html5" : true, "insid e_pos" : "last" },
      "types" : {
                  "#" : { "icon": "fas fa-universal-access icon_tree_tag" },
                  'tree_content': { "icon": "fas fa-tag icon_tree_tag" }
                },
      // Tri les élems par ID => même ordre que dans la BDD
      "sort" : function (a, b) { return (parseInt(a) < parseInt(b)) ? -1 : 1; }
  })
  // Drag & Drop : déplacement d'un tree_content dans l'arbre
  .bind("move_node.jstree", function(e, data) {
      console.log("Déplacement du node " + data.node.id + " vers " + data.parent);
      let url = $("#"+data.node.id+"_link").attr('data-fct-dnd');
      console.log(url);

      let datas = {};
      datas = {'meth_tree_content': { 'parent_id': data.parent}}
      $.ajax({
        url: url,
        type: 'PATCH',
        data: datas,
        dataType: 'script',
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));},
        success: function(data) {
          console.log('Update done.');
        }
      });
      data.instance.open_node(data.parent);
  })

  $('#expand_tree_content_tree').on("click", function() {
    $("#js_tree_tree_content").jstree("open_all");
  });
  $('#collapse_tree_content_tree').on("click", function() {
    $("#js_tree_tree_content").jstree("close_all");
  });
});
