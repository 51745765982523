document.addEventListener("turbolinks:load", () => {
  $("#sidebar").mCustomScrollbar({
      theme: "minimal" // "minimal-dark" // 'dark' // "minimal"
  });

  $('#sidebarCollapse').on('click', function () {
      $('#sidebar, #zone-content').toggleClass('active');
      $('.collapse.in').toggleClass('in');
      $('a[aria-expanded=true]').attr('aria-expanded', 'false');
  });

  $(".tool-menu-title").on('click', function(){
    $(".tool-menu-title").removeClass('tool-menu-title-active');
    $(this).addClass('tool-menu-title-active');
  });
});
