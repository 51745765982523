// import domtoimage from 'dom-to-image';
var domtoimage = require('dom-to-image');
// var html2canvas = require('html2canvas');

document.addEventListener("turbolinks:load", () => {
  $('.iskhikawa_zone').each(function(event){
    console.log('draw ishikawa ===>');
    draw_ishikawa($(this));
  });
});

// function test_html_canvas(div_id, img_id) {
//   console.log('DO CANVAS===========================>');
//   setTimeout(function(){
//     let elem = document.querySelector('#IshikawaDivJs');
//     let dest = document.querySelector('#ishi_out');
//     console.log(elem);
//     console.log(dest);

//     html2canvas(elem)
//       .then(canvas => {
//           console.log('DO CANVAS===========================> 2');
//           document.body.appendChild(canvas) // => pay attention to this line

//           const b64 = canvas.toDataURL("image/png").replace(/.*,/, '');
//           // theFunctionThatSendB64UsingAjax(b64)
//           console.log('DO CANVAS===========================> 3');
//           return;
//        });
//   }, 2000)
// }

function draw_ishikawa(ishikawa) {
  // Get Datas
  //let url = '';
  let url = ishikawa.attr('ishi_fct_datas');
  console.log(url);

  let ishikawa_id = ishikawa.attr('ishikawa_id');

  let params = {};
  params = { 'id': ishikawa.attr('ishikawa_id') };
  $.ajax({
    url: url,
    type: 'GET',
    data: params,
    dataType: 'json',
    beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));},
    success: function(datas) {
      console.log('Ajax done.');
      console.log(datas);

      // console.log('REPORT ??????????????????');
      // console.log(ishikawa.attr('ishi_show_buttons'))
      let rapport = ishikawa.attr('ishi_show_buttons') == 'true' ? 0 : 1;
      // console.log(rapport);

      dz_ishi_init_fct(ishikawa,
                       datas['options']['zoom'],
                       datas['options']['effect'],
                       datas['branchs']['list'].length, //nb_m,
                       datas['branchs']['list'], datas['branchs']['list_id'], //liste_m, liste_m_id,
                       datas['causes']['list'], datas['causes']['list_id'], datas['causes']['list_v'], // liste_c, liste_c_id, liste_c_v,
                       // opt_b1, opt_b2, opt_c1, opt_c2, opt_c3, url_options,
                       datas['options']['causes']['show_only_level1'], datas['options']['causes']['show_only_root'],
                       datas['options']['causes']['status']['in_progress'], datas['options']['causes']['status']['validated'], datas['options']['causes']['status']['invalidated'],
                       datas['options']['langue'],
                       rapport,
                       datas['ishikawa']);

      if (rapport == 1) {
        // divHtmltoImageReplace("#ishikawa_draw"+ishikawa_id, "#ishi_image"+ishikawa_id);
        divHtmltoImageReplaceAndSave("#ishikawa_draw"+ishikawa_id, "#ishi_image"+ishikawa_id, 'ishikawa_'+ishikawa_id);
        // divHtmltoImageReplaceAndSave("#ishikawa_draw"+ishikawa_id, "#ishi_div_img"+ishikawa_id, 'ishikawa_'+ishikawa_id);
      }
      // test_html_canvas("#ishi_test", "#ishi_image");
    }
  });
}

ISHI_TIP_bgcolor = "#555";
ISHI_textcolor = "#000";
SX1 = 800; SY1 = 450;
X0 = 180; //160;
X1 = 150;

ISHI_BLACKCOLOR = "#000";
ISHI_REDCOLOR = "#fd4e20";
ISHI_GREYCOLOR = "#ddd";
ISHI_GREENCOLOR = "#30b020";  //"#6db52f"

function get_x_i_mid(i, nb, sx, zoom)
{
  // if ( (nb/2.0) == Math.floor(nb / 2.0) ) {
  if (nb%2 == 0) {
    res = (SX1 - ((X0+X1)+10)) / ((nb/2) - 1);
    xi = (X0 + (Math.floor(i/2.0) * res)) * zoom;
  }
  else {
    res = (SX1 - (2*X0+10)) / (nb - 1);
    xi = (X0 + (i * res)) * zoom;
  }
  return xi;
}

function get_x_i_updown(xi, zoom)
{
  return xi - (105 * zoom);
}

function get_y_i_mid(i, zoom)
{
  return 225 * zoom;
}
function get_y_i_updown(xi, isup, zoom)
{
  res = (isup == 1) ? 45 * zoom : 405 * zoom;
  return res;
}

function context_set_shadow_ishi(context) {
  context.shadowColor = 'rgba(0, 0, 0, 0.4)';
  context.shadowBlur = 4;
  context.shadowOffsetX = 1;
  context.shadowOffsetY = 1;
}

function dz_ishi_init_fct(ishikawa, zoom, tab_effet, nb_m, liste_m, liste_m_id, liste_c, liste_c_id, liste_c_v, opt_b1, opt_b2, opt_c1, opt_c2, opt_c3, langue, rapport, datas_ishikawa) {
  //console.log("dz_ishi_init_fct");
  //console.log(rapport);
  let ishikawa_div = $('#IshikawaDivJs'+datas_ishikawa['id']);
  if (rapport==1) {console.log("RAPPORT TRUE");}
  else if (rapport==-1) {console.log("RAPPORT IS -1");}
  else {console.log("RAPPORT FALSE");}

  var context = $("#IshikawaCanvasJs"+datas_ishikawa['id'])[0].getContext('2d');
  var causes_color = ["#000", "#000", "#282", "#A00"];  // Couleur fonction de l'état de validation => 1=en attente/2=validée/3=invalidée
  var causes_color = ["#000", "#282", "#A00"];
  // Textes avec traductions
  var txt_c1 = ["Causes en attente de validation", "Causes pending validation"];
  var txt_c2 = ["Causes validées", "Validated causes"];
  var txt_c3 = ["Causes invalidées", "Invalidated causes"];
  var txt_c4 = ["Afficher uniquement causes de niveau 1", "Show only causes level 1"];
  var txt_c5 = ["Afficher uniquement causes racines", "Show only root causes"];
  var txt_help = ["ISHIKAWA :<br><b>Déplacement d'une cause :</b> Drag & Drop d'une cause sur le cadre de la branche<br><b>Supression d'une cause :</b> Cliquer icône 'x' et valider<br><b>Edition d'une cause :</b> Cliquer icône 'edit'",
                  "ISHIKAWA : <br><b>Move a cause : </b> Drag & Drop cause on branch<br><b>Delete a cause :</b> Click icon 'x' and validate<br><b>Edit a cause :</b> Click icon 'edit'"];
  var txt_lang = (langue == "fr") ? 0 : 1;
  //console.log(langue);
  //console.log(txt_lang);

  context.strokeStyle = ISHI_REDCOLOR;
  context.lineWidth = 3;

  // Draw M (Branches de l'Ishikawa)
  context.beginPath();
  context.moveTo(90*zoom, 225*zoom);
  context.lineTo((SX1*zoom-X1)+50, 225*zoom);
  context_set_shadow_ishi(context);
  context.stroke();

  //$div_ishi = $('#IshikawaDivJs');
  var isup = 0;
  for (var i=0; i<nb_m; i++) {
    // Branches (Lines)
    var xi, yi, xmid, ymid;
    xi = get_x_i_mid(i, nb_m, SX1, zoom);
    context.beginPath();
    var x1 = get_x_i_updown(xi, zoom);
    var y1 = get_y_i_updown(i, isup, zoom);
    context.moveTo(x1, y1+1);
    context.lineTo(xi, get_y_i_mid(i, zoom));
    context_set_shadow_ishi(context);
    context.stroke();

    // Branches (Boites + textes)

    let url_add = ishikawa.attr('ishi_fct_add');

    // url_edit = url_edit.replace("/id_de_la_cause", "/"+liste_c_id[idx][j])
    url_add += "?rca="+datas_ishikawa['rca']+"&tools_cause[rca_id]="+datas_ishikawa['rca']+"&tools_cause[tool_reference]="+datas_ishikawa['tool_ref']+"&ishikawa_id="+datas_ishikawa['id']+"&cause_type_id="+liste_m_id[i]
    console.log(url_add);

    ishikawa_div.append($('<div></div>')
        .attr("style", "position:absolute; left:"+(~~x1-40)+"px; top:"+((isup == 0)?~~y1:(~~y1-40))+"px;")
        .addClass("dropzone_ishi_txt")
        .text(liste_m[i])
      )
      // Ici 2 boites div pour éviter que le texte du div soit pris en compte comme un 2ième objet par le drag & drop.
      .append($("<div></div>").attr("id", liste_m_id[i])
        .attr("style", "position:absolute; left:"+(~~x1-40)+"px; top:"+((isup == 0)?~~y1:(~~y1-40))+"px;")
        .addClass("dropzone_ishi")
        .attr('dropzone', 'move')
      );

    if (rapport == 0) {
      ishikawa_div.append($('<a></a>') // .addClass("ishi_editc")
        .attr("style", "position:absolute; left:"+(~~x1+46)+"px; top:"+((isup == 0)?~~y1:(~~y1-40))+"px;")
        .attr("href", url_add)
        .attr("data-remote", "true")
        .append($(ishikawa.attr('ishi_icon_add')))
      );
    }

    // Causes de la branche i
    xmid = get_x_i_mid(i, nb_m, SX1, zoom);
    xi = get_x_i_updown(xmid, zoom);
    yi = get_y_i_updown(i, isup, zoom);
    ymid = get_y_i_mid(i, zoom);

    if (isup == 1) { var x1=xi, y1=yi, x2=xmid,y2=ymid; }
    else { var x1=xmid, y1=ymid, x2=xi,y2=yi; }

    idx = liste_m_id[i]
    for (var j=0; j<liste_c[idx].length; j++) {
      var sy = (y2-y1) / (liste_c[idx].length + 1);
        var sx = (x2-x1) / (liste_c[idx].length + 1);
      var xc = ~~(x1 + sx*(j+1)-50), yc = ~~(y1 + sy*(j+1)-10);

      var elt = $('<div></div>');
      ishikawa_div.append(elt.attr("id", liste_c_id[idx][j])
          .attr("style", "color:"+causes_color[liste_c_v[idx][j]]+"; left:"+~~xc+"px; top:"+(~~yc-10)+"px; width:"+(126*zoom)+"px;")
          .addClass("dzdrag_ishi")
          .text(liste_c[idx][j])
          .attr('draggable', 'true')
        );
      if (rapport == 0) {
        let url_delete = ishikawa.attr('ishi_fct_del');
        url_delete = url_delete.replace("/id_de_la_cause", "/"+liste_c_id[idx][j])

        let url_edit = ishikawa.attr('ishi_fct_edit');
        url_edit = url_edit.replace("/id_de_la_cause", "/"+liste_c_id[idx][j])
        url_edit += "?ishi=true"

        elt.append($('<a></a>').addClass("ishi_delc")
          .attr("href", url_delete)
          .attr("data-method", 'delete')
          .attr("data-confirm", 'Supprimer la cause (définitif) ?')
          .append($(ishikawa.attr('ishi_icon_del'))))
        .append($('<a></a>').addClass("ishi_editc")
          .attr("href", url_edit)
          .attr("data-remote", "true")
          .append($(ishikawa.attr('ishi_icon_edit')))
        );

      }
    }

    isup = (isup == 1) ? 0 : 1;
  }

  // Cadre + texte Effet
  var fy = 225*zoom - 30;
  var xx = (SX1*zoom-X1)+50, yy = fy+5; var EFY = yy;
  ishikawa_div.append($('<div></div>')
    .attr("style", "position:absolute; left:"+(xx)+"px; top:"+(yy-25)+"px;")
    .addClass("cadre_ishi").text(tab_effet[0]));

  // Causes sans "M"
  var xi, yi, xmid, ymid;
  xi = xx;
  yi = 10;
  ymid = EFY;
  var x1=xx, y1=yi+10, x2=xx+70, y2=ymid-30;

  // Boites causes sans 'M'
  i = 0
  ishikawa_div.append($('<div></div>').attr("id", '0')
      .attr("style", "position:absolute; left:"+(xi)+"px; top:"+yi+"px; width:140px; height:"+(EFY-yi-50)+"px;")
      .addClass("dropzone_ishi"));
  // Les causes sans 'M'
  if (liste_c[i].length > 0) {
    for (var j=0; j<liste_c[i].length; j++) {
      var sy = (y2-y1) / (liste_c[i].length + 1);
      if (sy > 30) sy=30;
      var sx = (x2-x1) / (liste_c[i].length + 1);
      var xc = ~~(x1 + sx*(j+1)-30), yc = ~~(y1 + sy*(j+1)-10);

      console.log(liste_c[i].length, x1, x2, sx, xc);

      var elt = $('<div></div>');
      ishikawa_div.append(elt.attr("id", liste_c_id[i][j])
                                   .attr("style", "color:"+causes_color[liste_c_v[i][j]]+"; left:"+~~xc+"px; top:"+(~~yc-10)+"px; width:"+(96*zoom)+"px; min-height:36px;")
                                   .addClass("dzdrag_ishi")
                                   .text(liste_c[i][j])
                                   .attr('draggable', 'true')
                                  );
      if (rapport == 0) {
        let url_delete = ishikawa.attr('ishi_fct_del');
        url_delete = url_delete.replace("/id_de_la_cause", "/"+liste_c_id[i][j])

        let url_edit = ishikawa.attr('ishi_fct_edit');
        url_edit = url_edit.replace("/id_de_la_cause", "/"+liste_c_id[i][j])
        url_edit += "?ishi=true"

        elt.append($('<a></a>').addClass("ishi_delc")
          .attr("href", url_delete)
          .attr("data-method", 'delete')
          .attr("data-confirm", 'Supprimer la cause (définitif) ?')
          .append($(ishikawa.attr('ishi_icon_del'))))
        .append($('<a></a>').addClass("ishi_editc")
          .attr("href", url_edit)
          .attr("data-remote", "true")
          .append($(ishikawa.attr('ishi_icon_edit')))
        );
      }
    }
  }

  // Options
  if (rapport != 1) {
    let url_opt = ishikawa.attr('ishi_fct_opt')+"?id="+datas_ishikawa['id'];
    ishikawa_div.append($('<span></span>')
        .addClass("ishi_bs")
        .attr("style", "left:"+~~(SX1*zoom-175)+"px; top:"+~~(SY1*zoom-1)+"px;")
        .text(txt_c4[txt_lang])
      .append($('<input>').attr("id", "cbcause1").addClass("ishi_bs")
        .attr("type", "checkbox").attr("checked", opt_b1)
        .on("click",function(){ $.post(url_opt+"&option=show_only_level1",function(html){}); }))
    );
    ishikawa_div.append($('<span></span>')
        .addClass("ishi_bs")
        .attr("style", "left:"+~~(SX1*zoom-175)+"px; top:"+~~(SY1*zoom-15)+"px;")
        .text(txt_c5[txt_lang])
      .append($('<input>').attr("id", "cbcause1").addClass("ishi_bs")
        .attr("type", "checkbox").attr("checked", opt_b2)
        .on("click",function(){ $.post(url_opt+"&option=show_only_root",function(html){}); }))
    );
    ishikawa_div.append($('<span></span>')
        .addClass("ishi_bs")
        .attr("style", "left:"+~~(SX1*zoom-175)+"px; top:"+~~(SY1*zoom-29)+"px;color:#D00;")
        .text(txt_c3[txt_lang])
        .append($('<input>').attr("id", "cbcause2").addClass("ishi_bs")
          .attr("type", "checkbox").attr("checked", opt_c3)
          .on("click",function(){ $.post(url_opt+"&groption=status&option=invalidated",function(html){}); })))
      .append($('<span></span>')
        .addClass("ishi_bs").attr("style", "left:"+~~(SX1*zoom-175)+"px; top:"+~~(SY1*zoom-43)+"px;color:#292;")
        .text(txt_c2[txt_lang])
        .append($('<input>')
          .attr("id", "cbcause3").addClass("ishi_bs")
          .attr("type", "checkbox").attr("checked", opt_c2)
          .on("click",function(){ $.post(url_opt+"&groption=status&option=validated",function(html){}); })))
      .append($('<span></span>')
        .addClass("ishi_bs").attr("style", "left:"+~~(SX1*zoom-175)+"px; top:"+~~(SY1*zoom-57)+"px;color:#000;")
        .text(txt_c1[txt_lang])
        .append($('<input>').attr("id", "cbcause4").addClass("ishi_bs")
          .attr("type", "checkbox").attr("checked", opt_c1)
          .on("click",function(){ $.post(url_opt+"&groption=status&option=in_progress",function(html){}); })));
  }
  else {
    ishikawa_div.append($('<span></span>')
        .addClass("ishi_bs")
        .attr("style", "left:"+~~(SX1*zoom-175)+"px; top:"+~~(SY1*zoom-1)+"px;")
      .append($.parseHTML(txt_c4[txt_lang] + ((opt_b1) ? "&nbsp;&nbsp;&nbsp;&#10004;" : "&nbsp;&nbsp;&nbsp;&#9633;"))));
    ishikawa_div.append($('<span></span>')
        .addClass("ishi_bs")
        .attr("style", "left:"+~~(SX1*zoom-175)+"px; top:"+~~(SY1*zoom-15)+"px;")
      .append($.parseHTML(txt_c5[txt_lang] + ((opt_b2) ? "&nbsp;&nbsp;&nbsp;&#10004;" : "&nbsp;&nbsp;&nbsp;&#9633;"))));
    ishikawa_div.append($('<span></span>')
        .addClass("ishi_bs")
        .attr("style", "left:"+~~(SX1*zoom-175)+"px; top:"+~~(SY1*zoom-29)+"px;color:#D00;")
      .append($.parseHTML(txt_c3[txt_lang] + ((opt_c3) ? "&nbsp;&nbsp;&nbsp;&#10004;" : "&nbsp;&nbsp;&nbsp;&#9633;"))))
      .append($('<span></span>')
        .addClass("ishi_bs")
        .attr("style", "left:"+~~(SX1*zoom-175)+"px; top:"+~~(SY1*zoom-43)+"px;color:#292;")
      .append($.parseHTML(txt_c2[txt_lang] + ((opt_c2) ? "&nbsp;&nbsp;&nbsp;&#10004;" : "&nbsp;&nbsp;&nbsp;&#9633;"))))
      .append($('<span></span>')
        .addClass("ishi_bs")
        .attr("style", "left:"+~~(SX1*zoom-175)+"px; top:"+~~(SY1*zoom-57)+"px;color:#000;")
      .append($.parseHTML(txt_c1[txt_lang] + ((opt_c1) ? "&nbsp;&nbsp;&nbsp;&#10004;" : "&nbsp;&nbsp;&nbsp;&#9633;"))));
  }

  // Options Zoom et Aide
  if (rapport != 1) {
    let url_zoom = ishikawa.attr('ishi_fct_zp');
    let url_zoom_p = url_zoom + "?id="+datas_ishikawa['id']+"&zoom=1"
    let url_zoom_m = url_zoom + "?id="+datas_ishikawa['id']+"&zoom=0"

    ishikawa_div.append($('<a></a>').addClass("ishi_zoom_pm ishi_zoom_p")
        .attr("href", url_zoom_p)
        .append($(ishikawa.attr('ishi_icon_zp'))))
      .append($('<a></a>').addClass("ishi_zoom_pm ishi_zoom_m")
        .attr("href", url_zoom_m)
        .append($(ishikawa.attr('ishi_icon_zm'))))
      .append($('<div></div>').attr("id", "ishi_txt_aide").addClass("ishi_aide")
        .html(txt_help[txt_lang]))
      .append($('<a></a>').addClass("ishi_zoom_pm ishi_b_aide")
        .attr("href", "#")
        .attr("data-remote", "true")
        .on({ mouseenter: function() { $("#ishi_txt_aide").css("visibility", "visible");},
              mouseleave: function() { $("#ishi_txt_aide").css("visibility", "hidden");} })
        .append($(ishikawa.attr('ishi_icon_help'))));
  }
  console.log("OK");

  // Call Drag & Drop setup
  if (rapport != 1) {
    $(document).ready(function(){
      dz_ishi_set_dnd(ishikawa);
    });
  }
}

/* Drag & Drop function :
 * Paramétrage du Drag & Drop
*/

function dz_ishi_set_dnd(ishikawa) {
  //console.log("Set DnD");
  var dnd_successful = false;
  var dz_rgb, drag_rbg = "#000";

  //console.log("Dz Drag");
  $(".dzdrag_ishi").each(function(idx,sel){
    /* DRAG START */
    $(this).bind("dragstart", function(event) {
      drag_rbg = event.target.style.background;
      event.target.style.background = "#7c7";//"green";

      event.originalEvent.dataTransfer.effectAllowed = "move";  // curseur type "move"
      event.originalEvent.dataTransfer.setData("text/plain", event.target.id);  // Obligatoirement un setData pour mozilla firefox (sinon les objets ne se déplacent pas)

      dnd_successful = false;
      // console.log("drag - start");
    });

    /* DRAG END */
    $(this).bind("dragend", function(event) {
      if (dnd_successful) {
        dnd_successful = false;
      }
      event.target.style.background = drag_rbg;
      // console.log("drag - end => " + event.target.style.background);
    });
  });

  console.log("Dz DropZones");
  /* DZ */
  //$("div[dropzone='move']").each(function(idx,sel){
  $(".dropzone_ishi").each(function(idx,sel){
    // console.log("Set Drop : " + $(this).attr('id'));

    $(this).bind('dragenter', function(event) {
      console.log("drag - enter");
      dz_rgb = event.target.style.background;
      dz_border = event.target.style.border;
      event.target.style.background = "#7c7";//"red";
      event.target.style.border = "2px solid #383";
      event.preventDefault();
      //console.log(event.target);
    });

    // $(this)[0].addEventListener('dragenter', function(event) {
    //   console.log("drag - enter");
    //   dz_rgb = event.target.style.background;
    //   dz_border = event.target.style.border;
    //   event.target.style.background = "#7c7";//"red";
    //   event.target.style.border = "2px solid #383";
    //   event.preventDefault();
    //   //console.log(event.target);
    // });

    $(this).bind('dragleave', function(event) {
    // $(this)[0].addEventListener('dragleave', function(event) {
      console.log("drag - leave");
      event.target.style.background = dz_rgb;
      event.target.style.border = dz_border;
    });

    $(this).bind('dragover', function(event) {
    // $(this)[0].addEventListener('dragover', function(event) {
      console.log("drag - over");
      event.originalEvent.dataTransfer.dropEffect = "move";
      event.preventDefault();
      return false;
    });

    $(this).bind('drop', function(event){
    // $(this)[0].addEventListener('drop', function(event){
      console.log("drop");
      event.preventDefault(); //Prevent Firefox from redirecting the page
      /*
       * Le Drag (cause) est laché dans une DZ (5M).
       * 1. On récupère les id du Drag et de la DZ
       * 2. Appel de la fonction rails "drag_cause" => modification de la BDD
       * 3. Déplacement de l'objet "Cause"
       * 4. On remet les couleurs d'origine de la DZ
       */
      let drag_id = event.originalEvent.dataTransfer.getData("text/plain");
      let drop_id = event.target.id;

      let url = ishikawa.attr('ishi_fct_drag');
      $.post(url, 'id='+(ishikawa.attr('ishikawa_id'))+'&drag_id='+drag_id+'&drop_id='+drop_id, function(html){});

      event.target.style.background = 'white';
      dnd_successful = true;

      event.preventDefault(); //Prevent Firefox from redirecting the page
      console.log("drag - drop : done");
    });
  });
  console.log("Dz End");
}

/* IMAGES */

// Transforme les données URL d'une image en Blob
function dataURLtoBlob(dataURL) {
    // Decode the dataURL
    var binary = atob(dataURL.split(',')[1]);
    // Create 8-bit unsigned array
    var array = [];
    for(var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    // Return our Blob object
    return new Blob([new Uint8Array(array)], {type: 'image/png'});
}

// // Crée une image avec le contenu de zediv, et la sauve sur le serveur avec le chemin/nom img (img sans RAISL_ROOT)
// function dataURLtoBlob(url) {
//   fetch(url)
//     .then(res => res.blob())
//     .then(blob => return blob)
// }

// function dataURLtoBlob( dataUrl, callback )
// {
//     var req = new XMLHttpRequest;

//     req.open( 'GET', dataUrl );
//     req.responseType = 'arraybuffer'; // Can't use blob directly because of https://crbug.com/412752

//     req.onload = function fileLoaded(e)
//     {
//         // If you require the blob to have correct mime type
//         var mime = this.getResponseHeader('content-type');

//         callback( new Blob([this.response], {type:mime}) );
//     };

//     req.send();
// }

function divHtmltoImageSave(zediv, img) {
  var node = $(zediv)[0];

  domtoimage.toPng(node)
      .then(function (dataUrl) {
        console.log("Save image on server");
        // Save
        var file= dataURLtoBlob(dataUrl);
        var fd = new FormData();
        fd.append("image", file);
        fd.append("imgname", img);
        $.ajax({
          url: $(zediv).attr('save-image-fct'), // "save_image_from_data",
          type: "POST",
          data: fd,
          processData: false,
          contentType: false,
        });
      })
      .catch(function (error) {
          console.error('oops, something went wrong!', error);
      });
}

// Crée une image avec le contenu de zediv, et la download avec le nom img
// Fonctionne sur Chrome mais pas sur Firefox (vérifier version ?)
function divHtmltoImageDownload(zediv, zea, img) {
  var node = $(zediv)[0];

  domtoimage.toPng(node)
      .then(function (dataUrl) {
        var a = $(zea)[0];
          //var a = document.createElement('a');  // l'objet a doit déjà exister pour fonctionner sur firefox.
      a.href = dataUrl;
      a.download = (img)?img+".png":"image.png";
      a.click();
      })
      .catch(function (error) {
          console.error('oops, something went wrong!', error);
      });
}

// // Affiche l'image du code html de zediv dans zeimg, et cache le div zediv
// // Dans le fichier html :
// // <div zediv> ... </div>
// // <img zeimg> ... </img>
function divHtmltoImageReplace(zediv, zeimg) {
  var node = $(zediv)[0];

  // Transform html div to image, et affiche l'image à la place de l'ishikawa
  domtoimage.toPng(node)
      .then(function (dataUrl) {
        // Affiche l'image et hide l'ishikawa
        var divimage = $(zeimg)[0];
        divimage.src = dataUrl;
        $(zediv).hide();
      })
      .catch(function (error) {
          console.error('oops, something went wrong!', error);
      });
}

function divHtmltoImageReplaceAndSave(zediv, zeimg, img) {
  var node = $(zediv)[0];

  domtoimage.toPng(node)
      .then(function (dataUrl) {
        console.log("Save image on server");
        // Save
        var file= dataURLtoBlob(dataUrl);
        var fd = new FormData();
        fd.append("image", file);
        fd.append("imgname", img);
        $.ajax({
          url: $(zediv).attr('save-image-fct'), // "save_image_from_data",
          type: "POST",
          //beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));},
          data: fd,
          processData: false,
          contentType: false,
          dataType: 'json',
          success: function(img_datas){
            // Affiche l'image et hide l'ishikawa
            var divimage = $(zeimg)[0];
            // name_image = "/ishikawas/"+img+'.png?'+(Math.floor(new Date().getTime() / 1000)).toString(); // dataUrl;
            divimage.src = img_datas['image']+'?'+(Math.floor(new Date().getTime() / 1000)).toString();
            // divimage.append(img_datas['image_tag']);
            $(zediv).hide();
          }
        });
      })
      .catch(function (error) {
          console.error('oops, something went wrong!', error);
      });
}
