/* Mise à jour des contenus des listes déroulantes si sélection dans la liste de référence
*  - appelé sur les éléments de formulaires de custom_field
*  - dans le fichier app/views/rcas/_form
*/

document.addEventListener("turbolinks:load", () => {
  $().on('ccascade', function() {
    console.log('CASCADE!!!');
  });

  // Mise à jour des custom fields
  $('.filtered-class').on('change ccascade', function(event) {
    console.log('Change element : ' + $(this).attr('id'));
    let url = $(this).attr('filtered-url')
    let datas = {value: $(this).val()}

    let ref_types_html = {'select': 'select',
                          'text': 'input',
                          'text_area': 'textarea',
                          'radio': 'input',
                          'tree': 'select',
                          'integer': 'input',
                          'date': 'input'}

    $.ajax({
      url: url,
      type: 'GET',
      data: datas,
      dataType: 'json',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));},
      success: function(data_returned) {
        console.log('Success.');
        console.log(data_returned['fields']);

        data_returned['fields'].forEach(element => {
          // console.log('HIDE FIELD ?' + element['display']);

          let prefix = '';
          let lelabel, leblock;

          if (element['type'] != 'radio') {
            prefix = 'rca_custom_field_attributes_'
            lelabel = $('label[for=' + prefix + element['internal_name'] +']');
            if (lelabel.length == 0) {
              prefix = 'custom_field_'
              lelabel = $('label[for=' + prefix + element['internal_name'] +']');
            }
            console.log('SEARCH LELABEL : ' + prefix + element['internal_name']);
            console.log(lelabel.attr('class'));
            leblock = lelabel.parent();
            console.log(leblock.attr('class'));
          }
          else {
            console.log('==================Radio button');

            prefix = 'rca_custom_field_attributes_'
            leblock = $('.' + prefix + element['internal_name'])
            if (leblock.length == 0) {
              prefix = 'custom_field_'
              console.log("Search class = " + '.' + prefix + element['internal_name'])
              leblock = $('.' + prefix + element['internal_name'])
            }
            lelabel = leblock.children('label');
            console.log('SEARCH LEBOCK ET LELABEL : ');
            console.log(leblock.attr('class'));
            console.log(lelabel.attr('class'));
          }



          // On cache le champs (et on enlève le required)
          if (element['display'] == 'none') {
            leblock.removeClass('required');
            // leblock.find('select').removeAttr('required');
            leblock.find(ref_types_html[element['type']]).removeAttr('required');

            leblock.css('display', 'none');
            leblock.find('label').find('abbr').remove();
          }

          else {
            // On rend le champs visible + si besoin on rajoute le require (si champs obligatoire et require non présent)
            leblock.css('display', '')
            leselect = leblock.find(ref_types_html[element['type']])

            console.log('INPUT HIDE/SHOW = ');
            console.log(leselect);
            leselect.each (function( index ) {
              console.log( index + ": " + $( this ).attr('id') );
            });
            console.log(leselect.attr('id'));
            console.log('ATTR VALUE = ' + leselect.attr('required'));
            console.log('PROP VALUE = ' + leselect.prop('required'));

            // if (leselect.attr('saved-required') == 'required' && leselect.attr('required') != 'required') {
            //   console.log('DO REQUIRE');
            //   leblock.addClass('required');
            //   leselect.attr( 'required', 'required' );
            //   leblock.find('label').append('<abbr title="required">*</abbr>');
            // }

            let block_done = 0
            leselect.each (function() {
              if ($(this).attr('saved-required') == 'required' && $(this).attr('required') != 'required') {
                console.log('DO REQUIRE');
                $(this).attr( 'required', 'required' );

                if (block_done == 0) {
                  leblock.addClass('required');
                  leblock.find('label').append('<abbr title="required">*</abbr>');
                  block_done = 1;
                }
              }
            });

            console.log('Element '+element['internal_name']);

            // Si champs de type select (liste déroulante) on reconstruit la liste de choix
            if (element['type'] == 'select') {
              let oldSel = $('#' + prefix + element['internal_name']).get(0);

              if (typeof(oldSel) != 'undefined') {
                while (oldSel.options.length > 0) {
                    oldSel.remove(oldSel.options.length - 1);
                }

                for (i = 0; i < element['options'].length; i++)
                {
                    var opt = document.createElement('option');

                    opt.text = element['options'][i].text;
                    opt.value = element['options'][i].value;

                    oldSel.add(opt, null);
                }
              }

              // Trigger for filtered lists in cascade.
              $(oldSel).trigger('ccascade');
            }
          }
        });
      }
    });
  });

  // Edition d'un Custom Field Desc : Mise à jour de la liste en fonction de ref_field
  $('#ref-field-update-list').click(function(){
    let url = $(this).attr('update-url'); // "<%= meth_custom_field_desc_update_list_dependencies_path %>";
    let datas = {value: $('#meth_custom_field_desc_ref_field_id').val()}

    $.ajax({
      url: url,
      type: 'GET',
      data: datas,
      dataType: 'json',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));},
      success: function(data_returned) {
        console.log('Success.');
        console.log(data_returned);

        // Si champs de type select (liste déroulante) on reconstruit la liste de choix
        let oldSel = $('#meth_custom_field_desc_ref_show_values').get(0);

        if (typeof(oldSel) != 'undefined') {
          while (oldSel.options.length > 0) {
              oldSel.remove(oldSel.options.length - 1);
          }

          for (i = 0; i < data_returned.length; i++)
          {
              var opt = document.createElement('option');

              opt.text = data_returned[i][0];
              opt.value = data_returned[i][1];

              oldSel.add(opt, null);
              console.log('Add option '+opt.text);
          }
        }
      }
    });
  });

});

