
// Prowhy custom Open/Close
//
// Permet de créer un bouton qui ouvre/ferme un élément html (référencé par son id)
// Exemple d'utilisation :
// <a href='#' data-toggle='prowhy-openclose'
//             data-prowhy-text='Details'
//             data-prowhy-type='open'
//             data-prowhy-content='#div_id'>
// </a>
// <div id='div_id style='display:none;'>
//   Contenu à afficher...
// </div>

document.addEventListener("turbolinks:load", () => {
  // Collapse Storage in LocalStorage
  // Use :
  // add class 'collapse-prowhy-ls' to shown/hidden part of collapse.
  // If default is opened : add class shown If default is closed : nothing more to do !
  function prowhy_collapse_load_save(div_name, div_header, vals_collapse_init) {
    // on page load, show or hide the div (and stylized the header) according to the localStorage (if it exists)
    var showDiv = vals_collapse_init ? vals_collapse_init[div_name] : null;
    if (showDiv == true) {
      console.log('TRUE OK');
      console.log(div_name);
      console.log(div_header)
      $('#'+div_header).attr('aria-expanded', 'true');
      $("#"+div_name).addClass("show");           // The div to show
      $("#header-"+div_name).find('.collapse-icon').html('<i class="fas fa-caret-up icon-right">');
    }
    else if (showDiv == false) {
      $("#"+div_name).removeClass("show");           // The div to show
      $("#header-"+div_name).find('.collapse-icon').html('<i class="fas fa-caret-down icon-right">');
    }
  };

  // Load OPEN/CLOSE STATUSES
  let vals_collapse_init =  JSON.parse(localStorage.getItem('prowhy-collapse'));
  $('.collapse-prowhy-ls').each(function(){
    prowhy_collapse_load_save($(this).attr('id'), "header-" + $(this).attr('id'), vals_collapse_init);
  })

  // Save when Show/Hide Actions
  $('.collapse-prowhy-ls').on('shown.bs.collapse', function () {
    let div_name = $(this).attr('id');
    $("#header-"+div_name).find('.collapse-icon').html('<i class="fas fa-caret-up icon-right">');

    let vals_collapse =  JSON.parse(localStorage.getItem('prowhy-collapse'));
    if (vals_collapse == null) { vals_collapse = {} }
    vals_collapse[div_name] = true;
    localStorage.setItem('prowhy-collapse', JSON.stringify(vals_collapse));
  });
  $('.collapse-prowhy-ls').on('hidden.bs.collapse', function () {
    let div_name = $(this).attr('id');
    $("#header-"+div_name).find('.collapse-icon').html('<i class="fas fa-caret-down icon-right">');

    let vals_collapse =  JSON.parse(localStorage.getItem('prowhy-collapse'));
    if (vals_collapse == null) { vals_collapse = {} }
    vals_collapse[div_name] = false;
    localStorage.setItem('prowhy-collapse', JSON.stringify(vals_collapse));
  });



  // prowhy-openclose
  function get_text_open(elem) {
    let txt = $(elem).attr("data-prowhy-text");
    return '<div style="min-width:80px;"><span class="prowhy-oc-text">' + txt + ' </span><i class="fas fa-caret-down icon-right"></i></div>';
  }
  function get_text_close(elem) {
    let txt = $(elem).attr("data-prowhy-text");
    return '<div style="min-width:80px;"><span class="prowhy-oc-text">' + txt + ' </span><i class="fas fa-caret-up icon-right"></i></div>';
  }
  $('[data-toggle="prowhy-openclose"][data-prowhy-type="open"]').html(function() {
    return get_text_open(this)
  });
  $('[data-toggle="prowhy-openclose"][data-prowhy-type="close"]').html(function() {
    return get_text_close(this)
  });

  $('[data-toggle="prowhy-openclose"]').on('click', function(e) {
    console.log("CLICK ON PW-IC !!!!");
    console.log($(this));
    let div = $(this).attr("data-prowhy-content");

    if ($(this).attr("data-prowhy-type") == 'open') {
      $(div).css('display', 'block');
      $(this).attr("data-prowhy-type", 'close');
      // $(this).html('<i class="fas fa-caret-square-up"></i>');
      $(this).html(function() { return get_text_close(this) });
    }
    else {
      $(div).css('display', 'none');
      $(this).attr("data-prowhy-type", 'open');
      // $(this).html('<i class="fas fa-caret-square-down"></i>');
      $(this).html(function() { return get_text_open(this) });
    }
    e.preventDefault();
  });

  $('[data-toggle="prowhy-openclose"]').on('pw-openclose-open', function(e) {
    console.log("Event OPEN");
    console.log($(this));

    let div = $(this).attr("data-prowhy-content");

    $(div).css('display', 'block');
    $(this).attr("data-prowhy-type", 'close');
    $(this).html(function() { return get_text_close(this) });

    e.preventDefault();
  });

  $('[data-toggle="prowhy-openclose"]').on('pw-openclose-close', function(e) {
    console.log("Event CLOSE");
    console.log($(this));

    let div = $(this).attr("data-prowhy-content");

    $(div).css('display', 'none');
    $(this).attr("data-prowhy-type", 'open');
    $(this).html(function() { return get_text_open(this) });

    e.preventDefault();
  });

  $('[data-toggle="prowhy-openclose"][data-prowhy-type="close"]').trigger('pw-openclose-open');

  // Spinner
  $('.prowhy_show_spinner').off('click');
  $('.prowhy_show_spinner').on('click', function() {
    if (!($(this).data('confirmtext')) || confirm($(this).data('confirmtext'))) {
      $('.prowhy_spinner').show();
      return true;
    }
    else {
      return false;
    }
  });
});
