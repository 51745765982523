
document.addEventListener("turbolinks:load", () => {
  $(".best_in_place").best_in_place().on('best_in_place:update', function(event) {
    $name = $(event.target);
    // console.log($name.html());
    // console.log($name.html().includes('fas fa-edit'));
    // console.log('BEST IN PLACE UPDATE !!!')

    if (! $name.html().includes('fas fa-edit') ) {
      $name.append(' <i class="fas fa-edit icon-blue"></i>');
    }
  });
})
